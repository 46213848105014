<template>
  <div class="app-container project">
    <div class="filter-container" style="margin-bottom: 10px">
      <el-input v-model="listQuery.projid" placeholder="项目ID" style="width: 200px;"
                class="filter-item" @keyup.enter.native="handleFilter"/>
      <el-input v-model="listQuery.projname" placeholder="项目名" style="width: 200px;"
                class="filter-item" @keyup.enter.native="handleFilter"/>
      <el-select v-model="listQuery.status" placeholder="状态" clearable style="width: 90px"
                 class="filter-item">
        <template v-for="status in statusStatus">
          <el-option :label="status.name" :value="status.value"></el-option>
        </template>
      </el-select>
      <el-select v-model="listQuery.parentid" placeholder="上级项目" clearable style="width:150px"
                 class="filter-item">
        <el-option label="全部" value=""></el-option>
        <template v-for="pro in parentProjectList">
          <el-option :label="pro.projname" :value="pro.projid"></el-option>
        </template>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleCreate">
        添加
      </el-button>
    </div>

    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="ID" sortable="custom" align="center" width="80">
        <template slot-scope="{row}">
          <span>{{ row.projid }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目名称" width="250px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.projname }}</span>
        </template>
      </el-table-column>
      <el-table-column label="上级项目名称" width="180px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.parentProjname }}</span>
        </template>
      </el-table-column>
      <el-table-column label="接入" width="150px" align="center">
        <template slot-scope="{row}">
          <span><a @click="handleGetLink(row)" style="color: #00bb00">接入链接</a></span>
        </template>
      </el-table-column>
      <el-table-column label="项目域名" width="200px">
        <template slot-scope="{row}">
          <span>{{ row.domain }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目状态" width="110px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.status | typeFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="客服人数" width="70px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.csCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目描述" width="250px">
        <template slot-scope="{row}">
          <span>{{ row.description }}</span>
        </template>
      </el-table-column>
      <el-table-column label="窗口寬度" width="250px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.linkWidth }}</span>
        </template>
      </el-table-column>
      <el-table-column label="窗口高度" width="250px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.linkHeight }}</span>
        </template>
      </el-table-column>
      <el-table-column label="小对话框名称" width="250px">
        <template slot-scope="{row}">
          <span>{{ row.linkName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户信息接口地址" width="250px">
        <template slot-scope="{row}">
          <span>{{ row.getuserurl }}</span>
        </template>
      </el-table-column>
      <el-table-column label="窗口最小化" width="250px">
        <template slot-scope="{row}">
          <span>{{ row.minimizeflag == 0 ? "否" : "是" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="对话框LOGO" width="100px">
        <template slot-scope="{row}">
          <!--          <el-upload-->
          <!--            class="avatar-uploader"-->
          <!--            :http-request="fileUploadFunction"-->
          <!--            action=""-->
          <!--            :show-file-list="false"-->
          <!--            :on-success="handleAvatarSuccess"-->
          <!--            :before-upload="beforeAvatarUpload">-->
          <img v-if="row.linkLogo" :src="row.linkLogo" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <!--          </el-upload>-->
        </template>
      </el-table-column>
      <el-table-column label="弹框顶部顔色" width="250px">
        <template slot-scope="{row}">
          <el-color-picker v-model="row.framecolor"></el-color-picker>
        </template>
      </el-table-column>
      <el-table-column label="无客服在线提示" width="500px">
        <template slot-scope="{row}">
          <span>{{ row.notonlinetext }}</span>
        </template>
      </el-table-column>
      <el-table-column label="按钮顔色" width="250px">
        <template slot-scope="{row}">
          <el-color-picker v-model="row.notonlinebtncolor"></el-color-picker>
        </template>
      </el-table-column>
      <el-table-column label="是否刪除" width="250px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.deleteflag == 0 ? "未刪除" : "已刪除" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" width="180">
        <template slot-scope="{row}">
          <span>{{ row.createtime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="修改时间" class-name="status-col" width="180">
        <template slot-scope="{row}">
          <span>{{ row.updatetime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="350"
                       class-name="small-padding fixed-width">
        <template slot-scope="{row,$index}">
          <el-button type="success" size="mini" @click="handlePreview(row)">
            预览
          </el-button>
          <el-button type="primary" size="mini" @click="handleSet(row)">
            设置
          </el-button>
          <el-button type="primary" size="mini" @click="handleUpdate(row)">
            修改
          </el-button>
          <el-button v-if="row.status!='1'" size="mini" type="success"
                     @click="handleModifyStatus(row,'1')">
            禁用
          </el-button>
          <el-button v-if="row.status!='0'" size="mini" @click="handleModifyStatus(row,'0')">
            解禁
          </el-button>
          <el-button v-if="row.status!='deleted'" size="mini" type="danger"
                     @click="handleDelete(row,$index)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page"
                :limit.sync="listQuery.limit" @pagination="getList"/>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px"
               style="width: 500px; margin-left:50px;">
        <el-form-item label="上级项目" prop="parentid">
          <el-select v-model="temp.parentid" placeholder="请选择上级项目" clearable style="width: 300px">
            <template v-for="pro in parentProjectList">
              <el-option v-if="pro.projid !=temp.projid" :label="pro.projname" :value="pro.projid"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="项目名" prop="projname">
          <el-input v-model="temp.projname" type="text" placeholder="请输入项目名"/>
        </el-form-item>
        <el-form-item label="域名" prop="domain">
          <el-input v-model="temp.domain" type="text" placeholder="请输入域名，多个域名之间用空格隔开"/>
        </el-form-item>
        <el-form-item label="项目描述" prop="description">
          <el-input v-model="temp.description" type="text" placeholder="请输入项目描述"/>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="接入链接" :visible.sync="dialogLinkVisible">
      <div style="color: red;text-align: center;font-size: 15px;">
        说明:请将此代码复制并粘贴到页面中。添加位置为页面源代码最底端的标签之前。
      </div>
      <div style="width: 80%;margin-left: 10%;margin-top: 50px;line-height: 30px" >{{ AccessLink }}
      </div>
    </el-dialog>

    <!--项目页面基本设置 -->
    <el-dialog title="基本设置" :visible.sync="dialogSetVisible">
      <el-form ref="dataForm" :rules="configSetRules" :model="configSet" label-position="left"
               label-width="150px" style="width:90%; margin-left:50px;">
        <el-form-item label="小对话框名称" prop="linkName">
          <el-input v-model="configSet.linkName" type="text" placeholder="请输入链接弹框名称"/>
        </el-form-item>
        <el-form-item label="用户信息接口地址">
          <el-input v-model="configSet.getuserurl" type="text" placeholder="请输入获取用户信息接口地址"/>
        </el-form-item>
        <el-form-item label="窗口最小化" prop="minimizeflag">
          <el-radio-group v-model="configSet.minimizeflag" style="line-height: 30px">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="对话框LOGO" prop="linkLogo">
          <el-upload
            class="avatar-uploader"
            :http-request="fileUploadFunction"
            action=""
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <img v-if="linkLogo" :src="linkLogo" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="弹框顶部颜色">
          <el-color-picker v-model="configSet.framecolor"></el-color-picker>
        </el-form-item>
        <el-form-item label="无客服在线提示" prop="notonlinetext">
          <el-input v-model="configSet.notonlinetext" type="textarea" rows="3"
                    placeholder="请输入无客服在线提示"/>
        </el-form-item>
        <el-form-item label="按钮颜色">
          <el-color-picker v-model="configSet.notonlinebtncolor"></el-color-picker>
        </el-form-item>
        <el-form-item label="自动断开提示" prop="autoofftext">
          <el-input v-model="configSet.autoofftext" type="textarea" rows="3"
                    placeholder="请输入自动断开提示文字"/>
        </el-form-item>
        <el-form-item label="自动断开时间" prop="autoofftime">
          <el-input v-model="configSet.autoofftime" type="number" placeholder="请输入用户自动断开时间/秒"/>
        </el-form-item>
        <el-form-item label="关于我们轮播图">
          <el-upload
            class="upload-demo"
            :http-request="fileUploadFunction"
            action=""
            :on-preview="handleCarPreview"
            :on-success="uploadConfig.uploadSuccess.bind(null,{item: configSet})"
            :on-remove="uploadConfig.onRemove.bind(null,{item: configSet})"
            :file-list="floatingframeImgurl"
            list-type="picture">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="关于我们介绍">
          <editor @onInit="getEditor" v-model="configSet.aboutIntroduce"></editor>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSetVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="updateSetData()">
          保存
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  getProject,
  addorupdateProject,
  deleteProject,
  updateProjectStatus,
  updateConfigSet,
  queryAllParentProject,
  getAccessLink
} from "@/apis/project";
import {parseTime} from '@/utils'
import editor from "@CMP/common/WangEditor";

const statusOptions = [
  {key: '0', display_name: '正常'},
  {key: '1', display_name: '禁用'},
]
const calendarTypeKeyValue = statusOptions.reduce((acc, cur) => {
  acc[cur.key] = cur.display_name
  return acc
}, {})
export default {
  name: 'projectinfo',
  components: {
    pagination: () => import('@/components/common/PagingPlug'),
    Editor: editor
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'info',
        deleted: 'danger'
      }
      return statusMap[status]
    },
    typeFilter(type) {
      return calendarTypeKeyValue[type]
    },
    parseTime: parseTime
  },
  data() {
    return {
      fileList: [],
      floatingframeImgurl: [],
      parentProjectList: [],
      initEditor: '',
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        projname: '',
        projid: '',
        status: '',
        parentid: '',
        auid: null
      },

      domains: [],
      statusOptions: ['published', 'draft', 'deleted'],
      showReviewer: false,
      temp: {
        projid: null,
        projname: '',
        domain: '',
        description: '',
        csCount: 0,
        parentid: '',
        adminuid: null
      },
      dialogFormVisible: false,
      dialogSetVisible: false,
      dialogStatus: '',
      textMap: {
        update: '修改',
        create: '添加'
      },
      dialogPvVisible: false,
      pvData: [],
      linkLogo: null,
      configSet: {
        id: null,
        projid: null,
        linkName: null,
        getuserurl: null,
        linkLogo: null,
        minimizeflag: null,
        aboutCarousel: null,
        proves: null,
        aboutIntroduce: null,
        framecolor: null,
        notonlinetext: null,
        notonlinebtncolor: null,
        autoofftext: null,
        autoofftime: null
      },
      configSetRules: {
        selecttypes: [{type: 'array', required: true, message: '请至少选择一个显示类型', trigger: 'change'}],
        linkName: [{required: true, message: '请输入链接弹框名称', trigger: 'change'}],
        minimizeflag: [{required: true, message: '请输入链接弹框名称', trigger: 'change'}],
        linkLogo: [{required: true, message: '请上传对话框LOGO', trigger: 'change'}],
        notonlinetext: [{required: true, message: '请输入客服不在线提示文字', trigger: 'change'}],
        autoofftext: [{required: true, message: '请输入自动断线提示文字', trigger: 'change'}],
        autoofftime: [{required: true, message: '请输入用户自动断开时间秒数', trigger: 'change'}]
      },
      rules: {
        projname: [{required: true, message: '请输入项目名', trigger: 'change'}],
        domain: [{required: true, message: '请输入项目域名', trigger: 'change'}],
        description: [{required: true, message: '请输入项目描述', trigger: 'change'}]
      },
      downloadLoading: false,
      dialogLinkVisible: false,
      AccessLink: null,
      uploadConfig: {
        uploadSuccess: ({item}, response, file, fileList) => {
          this.$set(item, 'proves', fileList);
          this.$message.success('文件上传成功！');
        },
        onRemove: ({item}, file, fileList) => {
          this.$set(item, 'proves', fileList);
        },
        beforeUpload: ({item}, file) => {
          let result = false;
          const isLtSize = file.size / 1024 / 1024 < 1;
          if (!isLtSize) {
            this.$message.error(`上传文件大小不能超过 1MB!`);
            return false;
          }
          result = isLtSize;
          return result && this.$message('文件正在上传') && true;
        },
        uploadFileError() {
          this.$message.error('文件上传失败')
        },
      }
    }
  },
  created() {
    this.getList();
    this.queryAllParentProject();
  },
  methods: {
    getList() {
      this.listLoading = true
      this.listQuery.adminuid = this.user.userId
      getProject(this.listQuery).then(res => {
        const {retdata = [], totalCount = 0} = res;
        this.list = retdata;
        this.total = totalCount;
        this.listLoading = false;
      })
    },
    queryAllParentProject() {
      this.listQuery.adminuid = this.user.userId
      queryAllParentProject(this.listQuery).then(res => {
        const {data = [], count = 0} = res;
        this.parentProjectList = data;
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleModifyStatus(row, status) {
      this.temp = Object.assign({}, row)
      var projid = this.temp.projid;
      updateProjectStatus({projid, status}).then(res => {
        this.$message.success(res.retDesc);
        row.status = status
      })
    },
    resetTemp() {
      this.temp = {
        projid: undefined,
        projname: null,
        domain: null,
        description: null,
        auid: null,
        framecolor: null,
        notonlinetext: null,
        notonlinebtncolor: null,
        parentid: null,
        autoofftext: null,
        appsUrl: null,
        autoofftime: null
      }
    },
    handleCreate() {
      this.domains = [];
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.temp.adminuid = this.user.userId
          var domain = this.temp.domain;
          if (domain.indexOf("https://") != -1 || domain.indexOf("http://") != -1) {
            this.$message.error("域名不允许输入前缀");
            return false;
          }
          addorupdateProject(this.temp).then(res => {
            this.$message.success(res.retDesc);
            this.dialogFormVisible = false;
            this.getList();
          });
        }
      })
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
      if (this.temp.parentid == 0) {
        this.temp.parentid = null;
      }
    },
    handlePreview(row) {
      this.temp = Object.assign({}, row) // copy obj
      let routeData = this.$router.resolve(
        {path: '/vclient/robot', query: {projid: this.temp.projid}});
      window.open(routeData.href, '_blank');
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.temp.adminuid = this.user.userId
          var domain = this.temp.domain;
          if (domain.indexOf("https://") != -1 || domain.indexOf("http://") != -1) {
            this.$message.error("域名不允许输入前缀");
            return false;
          }
          addorupdateProject(this.temp).then(res => {
            this.$message.success(res.retDesc);
            this.dialogFormVisible = false;
            this.getList();
          });
        }
      })
    }, getEditor(editor) {
      this.initEditor = editor;
      this.initEditor.txt.html(this.configSet.aboutIntroduce)
    },
    formatJson(filterVal) {
      return this.list.map(v => filterVal.map(j => {
        if (j === 'timestamp') {
          return parseTime(v[j])
        } else {
          return v[j]
        }
      }))
    },
    handleDelete(row, index) {
      this.temp = Object.assign({}, row)
      var projid = this.temp.projid;
      if (this.temp.csCount != 0) {
        this.$message.error("项目下有客服，不能删除");
        return false;
      }
      deleteProject({projid}).then(res => {
        this.$message.success(res.retDesc);
        this.list.splice(index, 1)
      });
    }, handleSet(row) {
      this.dialogSetVisible = true;
      this.configSet = {
        id: null,
        projid: null,
        linkName: null,
        getuserurl: null,
        minimizeflag: 0,
      };
      this.fileList = [];
      this.configSet = Object.assign({}, row)
      // console.log(this.configSet.floatingframeImgurl)
      // console.log(this.fileList)
      this.fileList.push(this.configSet.linkLogo)
      if (this.initEditor != '') {
        this.initEditor.txt.html(row.aboutIntroduce)
      }
      var params =  row.aboutCarousel.split(',')
      this.floatingframeImgurl = params.map(item=>{
        return {
          url:item,
          path: item,
          name: item.substring(item.lastIndexOf('/') + 1)
        }
      })
      // this.floatingframeImgurl = row.floatingframeImgurl
      // console.log(row)
      if (row.aboutCarousel != null) {
        if (row.aboutCarousel.split(',') != null) {
          this.fileList = row.aboutCarousel && row.aboutCarousel.split(',').map(
            item => {
              return {
                url: item,
                path: item,
                name: item.substring(item.lastIndexOf('/') + 1)
              }
            }) || [];
        }
      }

      this.linkLogo = row.linkLogo;
    }, updateSetData() {
      //保存基本配置
      this.configSet.aboutIntroduce = this.initEditor.txt.html();
      console.log(this.configSet.proves)
      this.configSet.aboutCarousel = this.configSet.proves && this.configSet.proves.map(
        item => item.path || item.response.src).join(',') || null;
      this.configSet.updatetime = parseInt(new Date().getTime() / 1000 + '')
      console.log(this.configSet)

      updateConfigSet(this.configSet).then(res => {
        this.$message.success(res.retDesc);
        this.dialogSetVisible = false;
        this.getList();
      });
    }, handleAvatarSuccess(res, file) {
      this.configSet.linkLogo = res.src;
      this.linkLogo = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或者 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    }, handleGetLink(row) {
      this.temp = Object.assign({}, row)
      this.dialogLinkVisible = true
      getAccessLink(this.temp).then(res => {
        this.AccessLink = res.retdata;
      })
    },
    handleCarPreview(file) {
      console.log(file);
    }, uploadSuccess: ({item}, response, file, fileList) => {
      console.log(response)
      this.$set(item, 'proves', fileList);
      this.$message.success('文件上传成功！');
    },
    handleRemove: ({item}, file, fileList) => {
      this.$set(item, 'proves', fileList);
    }, resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain(item) {
      var index = this.domains.indexOf(item)
      if (index !== -1) {
        this.domains.splice(index, 1)
      }
    },
    addDomain() {
      this.domains.push({
        value: '',
        key: Date.now()
      });
    }
  }, computed: {
    statusStatus() {
      return [
        {name: '全部', value: null},
        {name: '禁用', value: 1},
        {name: '正常', value: 0}
      ]
    },
  },
}
</script>

<style>
.project .filter-item {
  margin-left: 5px;
}

.project .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader {
  font-size: 10px;
}

.project .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.project .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.project .avatar {
  width: 50px;
  height: 50px;
  display: block;
}
</style>
