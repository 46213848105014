/**
 * 项目js
 */

import axios from "@/resources/js/request";
import da from "element-ui/src/locale/lang/da";

const request = axios.request;
/**
 * 获取项目信息
 * @returns {Promise<unknown>}
 */
export const getProject = (data) => {
  return request.doPost('/project/getProject', data);
}

/**
 * 添加或者修改项目信息
 * @returns {Promise<unknown>}
 */
export const addorupdateProject = (data) => {
  return request.doPost('/project/addorupdateProject', data);
}

/**
 * 删除项目信息
 * @returns {Promise<unknown>}
 */
export const deleteProject = (data) => {
  return request.doPost('/project/deleteProject', data);
}

/**
 * 修改项目状态
 * @returns {Promise<unknown>}
 */
export const updateProjectStatus = (data) => {
  return request.doPost('/project/updateProjectStatus', data);
}
/**
 * 修改项目客服页面基本配置
 * @returns {Promise<unknown>}
 */
export const updateConfigSet = (data) => {
  return request.doPost('/project/updateConfigSet', data);
}

/**
 * 获取接入链接
 * @returns {Promise<unknown>}
 */
export const getAccessLink = (data) => {
  return request.doPost('/project/getAccessLink', data);
}

/**
 * 根据客服获取项目信息
 * @returns {Promise<unknown>}
 */
export const getProjectByCsid = (data) => {
  return request.doPost('/project/getProjectByCsid', data);
}

/**
 * 获取所有父类项目
 * @returns {Promise<unknown>}
 */
export const queryAllParentProject = (data) => {
  return request.doPost('/project/queryAllParentProject', data);
}
